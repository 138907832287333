import * as styles from '../page-styles/About.module.css';
import Card from '../components/ui/Card/Card.jsx';
import Headshot from '../images/headshot.jpg';

import Layout from '../components/Layout/Layout';
import {graphql} from 'gatsby';

import Seo from '../components/seo';

import { useEffect } from 'react';
import * as React from 'react';

const About = ({data}) => {

const pageData = data.allDataYaml.edges[0].node;

useEffect(()=>{
    if(typeof window !== 'undefined'){
    window.scrollTo(0,0)
    }  
}, [])

    return (
        <Layout location='about'>
         <Seo 
         title={pageData.title}
         description={pageData.description}/>
        <main className={styles.about}>

            <Card>
                <h1>About Michael Kolonay Renovations</h1>
                <div className={styles.headerUnderline}></div>

                <div className={styles.paragraphContainer}>
                    <h2>A small business with a personal touch</h2>
                    <p>Michael Kolonay Renovations is a remodeling company owned and operated by myself: Michael Kolonay. I am the only employee, and along with a few trusted subcontractors for skilled trades, I am responsible for every detail of my projects.</p>

                </div>
                <div className={styles.paragraphContainer}>
                    <h2>A standard of excellence</h2>
                    <p>Working alone has its challenges, but I consider it an asset. I cannot keep pace with big companies, but I have control of the standard of excellence of every task. Keeping overhead low allows me to strive for perfection while maintaining reasonable prices.</p>
                </div>
                <div className={styles.paragraphContainer}>
                    <h2>A trusted adviser</h2>
                    <p>My clients see me as a trusted adviser. They appreciate my flexibility and willingness to try new things as well as my attention to detail. They know I won’t turn a blind eye to problems I encounter as a project unfolds in the name of simply maintaining an arbitrary schedule.</p>
                </div>
                <div className={styles.paragraphContainer}>
                    <h2>A licensed and insured company</h2>
                    <p>Micael Kolonay Renovations LLC is a licensed and insured contractor in the state of Pennsylvania. </p>
                    <p><b><i>HIC# - PA137898</i></b></p>
                </div>
                <div className={styles.photoContainer}>
                    <img src={Headshot} alt="Michael Kolonay" />
                    <div className={styles.name}>
                        <h2>Mike Kolonay</h2>
                        <p><i>Owner of Michael Kolonay Renovations</i></p>
                    </div>
                </div>
            </Card>
        </main>
        </Layout>

    )
}

export const query = graphql`
query aboutQuery {
    allDataYaml(filter: {page: {eq: "about"}}) {
      edges {
        node {
          description
          title
        }
      }
    }
  }
  
`

export default About